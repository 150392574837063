form {
    background: white;
    padding: 2rem;
    margin-top: 2rem;
    width: 100%;
    max-width: 40rem;
    box-shadow: 0 0 1rem rgba(black, 0.2);
    * {
        font-size: 1.6rem; }
    input[type="text"],
    input[type="email"],
    textarea {
        padding: 0.5rem 1rem;
        display: block;
        margin-bottom: 1rem;
        width: 100%;
        outline: none;
        border: 1px solid $hellgrau;
        &:focus {
            border: 1px solid $magenta; } }
    .checkbox--group {
        margin: 2rem 0;
        display: flex;
        align-items: flex-start;
        input {
            margin-right: 1rem;
            top: 5px;
            position: relative; } }
    .error {
        margin-top: 2rem;
        color: red; }
    button {
        cursor: pointer; }
    &#feedback--form {
        max-width: 80rem;
        margin: 3rem auto;
        background: white;
        h2 {
            font-size: 2rem;
            margin-bottom: 2rem; } } }


#feedback {
    padding: 2rem;
    background: $hellgrau; }
