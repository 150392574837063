header {
    min-height: 100vh;
    background: url('../img/ot_more_keyvisual.jpg') center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    .content {
        position: relative;
        min-height: calc(100vh - 4rem);
        #logo-more {
            position: absolute;
            left: 0;
            top: 0;
            max-width: 40vw; }
        #logo {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 25vw; }
        #down {
            position: absolute;
            left: 50%;
            bottom: 0rem;
            transform: translate(-50%,0);
            animation: upDown forwards infinite 3s ease; }
        h1 {
            margin-top: 6rem;
            color: white;
            text-shadow: 0 0 0.6rem rgba(black, 0.2);
            font-size: $xl;
            line-height: 1.2;
            margin-bottom: 1rem;
            font-family: $bold;
            @media (min-width: $tablet) {
                margin-top: 8rem;
                font-size: $xxl; } }
        h2 {
            font-size: $l;
            color: white;
            font-family: $regular;
            text-shadow: 0 0 0.6rem rgba(black, 0.2);
            max-width: 45rem;
            line-height: 1.2;
            @media (min-width: $tablet) {
                font-size: $xl; } } }
    &#login {
        background: url('../img/vorhang-4k.jpg') center no-repeat;
        background-size: cover;
        background-attachment: fixed; }
    &#teaser {
        background: url('../img/vorhang-4k.jpg') center no-repeat;
        background-size: cover;
        background-attachment: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .teaser--content {
            width: 60rem;
            max-width: 40vw;
            background: rgba(white, 0.8);
            padding: 2rem;
            h1 {
                font-size: 3rem;
                margin-bottom: 2rem;
                font-weight: bold; } }
        @media (max-width: $tablet) {
            align-items: flex-start;
            .teaser--content {
                max-width: 100%;
                width: 100%; } } } }




