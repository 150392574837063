#login {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(black, 0.8); }
    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h1 {
            margin-top: 0; } } }
