footer {
    padding: 2rem;
    padding-bottom: 9rem;
    background: black;
    * {
        color: white; }
    text-align: center;
    .right--content {
        @media (min-width: $mobile) {
            text-align: right; } }
    .left--content {
        margin-bottom: 2rem;
        @media (min-width: $mobile) {
            text-align: left;
            margin-bottom: 0; } } }
