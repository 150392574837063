* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  text-transform: none;
  text-decoration: none; }

html, body {
  height: 100%;
  line-height: 1.6; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

.content {
    width: 100%;
    max-width: 140rem;
    margin: 0 auto; }
img {
    display: block;
    max-width: 100%;
    height: auto; }

small {
    margin-top: 6rem;
    display: block; }

p, a, strong, li {
    color: black;
    font-size: 1.4rem;
    @media (min-width: $tablet) {
        font-size: $ml; } }
strong, span {
    font-size: inherit; }
strong {
    font-family: $bold; }
h1, h2, h3, h4 {
    line-height: 1.2; }
h3 {
    font-family: $bold;
    font-size: $l;
    margin-bottom: 2rem;
    span {
        font-family: $regular; }
    @media (min-width: $desktop) {
        font-size: 5rem; } }
h4 {
    font-family: $regular;
    font-size: 2rem;
    margin-bottom: 3rem;
    @media (min-width: $desktop) {
        font-size: 4rem; } }

